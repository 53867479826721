import React, { useState } from 'react';
import './PhotoSlider.scss';
import Container from 'react-bootstrap/Container';
import PropTypes from 'prop-types';
import photos from './photos.json';
import navButton from './next.svg';


const PhotoSet = ({ set }) => (
  <div className="photoslider__photoset">
    <span className="img" style={{ backgroundImage: `url(${set[0]})` }} />
    <span className="img" style={{ backgroundImage: `url(${set[1]})` }} />
  </div>
);

PhotoSet.propTypes = {
  set: PropTypes.arrayOf(PropTypes.any).isRequired,
};

const PhotoSlider = () => {
  const [page, setPage] = useState(0);
  const wrapperClass = `photoslider__container__wrapper ps--${page}`;

  const handlePrevious = () => {
    if (page - 1 >= 0) {
      setPage(page - 1);
    }
  };

  const handleNext = () => {
    if (page + 1 < photos.length) {
      setPage(page + 1);
    }
  };

  return (
    <section className="photoslider">
      <Container className="photoslider__content">
        <button className="photoslider__navigate photoslider__navigate--prev" type="button" onClick={handlePrevious}>
          <img src={navButton} alt="previous" />
        </button>
        <div className="photoslider__container">

          <div className={wrapperClass}>
            {photos.map((set) => (<PhotoSet set={set} />))}
          </div>

        </div>
        <button className="photoslider__navigate photoslider__navigate--next" type="button" onClick={handleNext}>
          <img src={navButton} alt="next" />
        </button>
      </Container>
    </section>
  );
};

export default PhotoSlider;
