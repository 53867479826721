import React from 'react';
import { Slide } from 'react-slideshow-image';
import PropTypes from 'prop-types';
import './PhotoCarousel.scss';

const properties = {
  duration: 5000,
  transitionDuration: 300,
  infinite: true,
  arrows: true,
};
/* eslint-disable react/jsx-props-no-spreading */


const PhotoCarousel = ({ slides }) => (
  <div className="photocarousel">
    <Slide {...properties}>
      {slides.map((slide) => (
        <div className="each-slide">
          <div style={{ backgroundImage: `url(${slide})` }} />
        </div>
      ))}
    </Slide>
  </div>
);

PhotoCarousel.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default PhotoCarousel;
