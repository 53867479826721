import React from 'react';
import './MainMenu.scss';
import classNames from 'class-names';
import { useSelector, useDispatch } from 'react-redux';
import close from '../../assets/close-icon-black.svg';

function MainMenu() {
  // eslint-disable jsx-a11y/media-has-caption

  const mainMenuOpen = useSelector((state) => state.mainMenuOpen);
  const dispatch = useDispatch();


  return (
    <div className={classNames('mainmenu', { 'mainmenu--open': mainMenuOpen })}>
      <button type="button" className="mainmenu__close" onClick={() => dispatch({ type: 'TOGGLE_MAIN_MENU' })}>
        <img src={close} alt="close" className="mainmenu__close__img" />
      </button>

      <ul>
        <li>Home</li>
        <li>Werk</li>
        <li>Klanten</li>
        <li>Contact</li>
      </ul>
    </div>
  );
}

export default MainMenu;
