import React from 'react';
import Jumbotron from 'react-bootstrap/Jumbotron';
import './HeaderJumbo.scss';
import logo from '../../assets/mier-logo-ns.png';

function HeaderJumbo() {
  // eslint-disable jsx-a11y/media-has-caption
  return (
    <Jumbotron className="headerjumbo">

      <div className="headerjumbo__logo">
        <img src={logo} alt="logo" />
      </div>
      <h1>Expect the unexpected</h1>

    </Jumbotron>
  );
}

export default HeaderJumbo;
