import React from 'react';
import './LogoSection.scss';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';


function LogoSection() {
  return (
    <div className="logosection">
      <Container>
        <h1>In trotse samenwerking met</h1>
        <Row>
          <Col xs={6} sm={{ span: 3, offset: 1 }} className="py-4 logosection__logo">
            <img src="/images/logo-inrichterij.png" alt="logo" />
          </Col>
          <Col xs={6} sm={{ span: 3, offset: 1 }} className="py-4 logosection_logo">
            <img src="/images/logo-dreamwell.svg" alt="logo" />
          </Col>
          <Col xs={{ span: 6, offset: 3 }} sm={{ span: 3, offset: 1 }} className="py-4 logosection_logo">
            <img src="/images/logo-hoekse.png" alt="logo" />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default LogoSection;
