import React from 'react';
import './About.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function About() {
  // eslint-disable jsx-a11y/media-has-caption
  return (
    <div className="about">
      <Container className="py-5">
        <Row className="py-5">
          <Col sm={{ offset: 0, span: 3 }} className="pt-3 d-none d-md-block">
            <img src="/images/foto-mier.png" alt="" width="100%" />
          </Col>
          <Col xs={12} md={9} className="pl-md-4 col-sm-push-4">
            <h1>Wie is Mier?</h1>
            <p className="pt-2">
              Even voorstellen...
              <br />
              Mireille Both-van Tol, getrouwd en moeder van 4 prachtige dochters.
              Al mijn hele leven bezig in de creatieve sector en werkzaam in de mode.
              Mode en interieur liggen dicht bij elkaar qua trends volgen en zodoende
              richtte ik mij steeds vaker op interieur.
              En aangezien ik niet iedere 3 maanden mijn eigen huis kan verbouwen
              {' '}
              <span className="d-md-none">;-)</span>
              {' '}
besloot
              ik om mijn enthousiasme te delen met anderen!
              <br />
              <br />
Een interieur is geslaagd als de bewoners er écht thuis kunnen komen,
maar het toch iedere keer weer speciaal voelt.
Dat ze trots zijn op bv een nieuwe fauteuil, gecombineerd met dat oude kastje van oma.
              <br />
Mix en match, maar het tóch een eenheid maken, daar houd ik van.
              <br />
En iets geks of totaal onverwachts mag niet ontbreken.
              <br />
            </p>
          </Col>
          <Col xs={{ offset: 2, span: 8 }} className="pt-5 d-md-none ">
            <img src="/images/foto-mier.png" alt="" width="100%" />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default About;
