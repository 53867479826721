import React from 'react';
import {
  BrowserRouter as Router,
} from 'react-router-dom';
import classNames from 'class-names';

import './App.scss';
import { useSelector, useDispatch } from 'react-redux';
import Header from '../components/Header/Header';
import MainMenu from '../components/MainMenu/MainMenu';
import HeaderJumbo from '../components/HeaderJumbo/HeaderJumbo';
import PhotoSlider from '../components/PhotoSlider/PhotoSlider';
import About from '../components/About/About';
import MediaSection from '../components/MediaSection/MediaSection';
import Pricing from '../components/Pricing/Pricing';
import Contact from '../components/Contact/Contact';
import LogoSection from '../components/LogoSection/LogoSection';

function App() {
  const mainMenuOpen = useSelector((state) => state.mainMenuOpen);
  const dispatch = useDispatch(); //eslint-disable-line
  let headerStatus = false;

  const handleWindowScroll = () => {
    if (!headerStatus && window.scrollY > 130) { //eslint-disable-line
      dispatch({ type: 'HEADER_SET_VISABILITY', status: true });
      headerStatus = true;
    } else
    if (headerStatus && window.scrollY < 130) { //eslint-disable-line
      dispatch({ type: 'HEADER_SET_VISABILITY', status: false });
      headerStatus = false;
    }
  };
  window.addEventListener('scroll', handleWindowScroll); //eslint-disable-line

  return (
    <div>
      <Header />

      <div className={classNames('jvn-site', { 'jvn-site--mainmenuopen': mainMenuOpen })}>
        <Router>
          <HeaderJumbo />
          <PhotoSlider />
          <About />
          <MediaSection />
          <Pricing />
          <Contact />
          <LogoSection />
        </Router>
      </div>
      <MainMenu />
    </div>
  );
}

export default App;
