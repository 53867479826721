import React, { useState, useRef } from 'react';
import './Contact.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


function Contact() {
  const [mailSent, setMailSent] = useState(false);
  const inputEmail = useRef(null);
  const inputMessage = useRef(null);


  const sendMail = (e) => {
    e.preventDefault();
    fetch('https://www.mierinterieur.nl/api/mail/send', { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ sender: inputEmail.current.value, message: inputMessage.current.value }) }) // eslint-disable-line no-undef
      .then((response) => response.json())

      .then((data) => {
        if (data.success === true) {
          setMailSent('success');
        } else {
          setMailSent('error');
        }
      });
  };
  return (
    <div className="contact">
      <Container>
        <Row>
          <Col xs={12} md={4}>
            <h1>Meer weten?</h1>
            <p>
              <strong>Mier Interieur</strong>
              <br />
              info@mierinterieur.nl
              <br />
              <br />
              BTW: NL003115024B88
              <br />
              KvK: 76729958
            </p>
          </Col>
          {(!mailSent || mailSent === 'error') && (
          <Col className="mt-5 mt-md-0" xs={12} md={{ span: 6, offset: 2 }}>
            <form onSubmit={sendMail}>
              <input type="text" placeholder="e-mailadres" ref={inputEmail} />
              <br />
              <textarea placeholder="bericht" rows="5" ref={inputMessage} />
              <br />
              <input type="submit" value="verstuur" />
              <br />
              <br />
              {mailSent === 'error' && <h5>Er is een fout opgetreden. Probeer het nogmaals, of stuur uw bericht naar info@mierinterieur.nl</h5>}
            </form>
          </Col>
          )}
          {mailSent === 'success' && (
          <Col className="mt-5 mt-md-0" xs={12} md={{ span: 6, offset: 2 }}>
            <h3>
Je bericht is succesvol verzonden!
              <br />
Ik neem z.s.m. contact met je op.
            </h3>
          </Col>
          )}
        </Row>
      </Container>
    </div>
  );
}

export default Contact;
