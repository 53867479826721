import React from 'react';
import classNames from 'class-names';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import './Header.scss';
import { useSelector } from 'react-redux';
// import hamburger from './hamburger.svg';
import logo from '../../assets/mier-logo-ns.png';


function Header() {
  // const dispatch = useDispatch();
  const headerIsFixed = useSelector((state) => state.headerIsFixed);
  const mainMenuOpen = useSelector((state) => state.mainMenuOpen);
  return (
    <div className={classNames('header', { 'header--fixed': headerIsFixed || mainMenuOpen })}>
      <Container>
        <Row>
          <Col xs={8} sm={6}>
            <img src={logo} alt="logo" className="header__logo" />
          </Col>
          {/* <Col xs={4} sm={6} className="text-right">
            <button type="button"
            onClick={() => { dispatch({ type: 'TOGGLE_MAIN_MENU' }); }} className="header__close">
              <img src={hamburger} alt="logo" className="header__close__hamburger" />
            </button>
          </Col> */}
        </Row>
      </Container>
    </div>
  );
}

export default Header;
