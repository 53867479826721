import React from 'react';
import './MediaSection.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PhotoCarousel from '../PhotoCarousel/PhotoCarousel';

function MediaSection() {
  return (
    <div className="mediasection">
      <Container>
        <Row>
          <Col xs={12} md={6} className="mediasection__col">
            <h2>Moodboards</h2>

            <PhotoCarousel slides={
              ['/images/moodboards/6.jpg',
                '/images/moodboards/7.jpg',
                '/images/moodboards/8.jpg',
                '/images/moodboards/9.jpg',
                '/images/moodboards/10.jpg',
                '/images/moodboards/11.jpg',
                '/images/moodboards/12.jpg',
                '/images/moodboards/13.jpg',
                '/images/moodboards/14.jpg',
                '/images/moodboards/15.jpg',
              ]
}
            />
          </Col>
          <Col xs={12} md={6} className="mediasection__col">
            <h2>Tekeningen</h2>

            <PhotoCarousel slides={[
              '/images/tekeningen/9.jpg',
              '/images/tekeningen/10.jpg',
              '/images/tekeningen/11.jpg',
              '/images/tekeningen/12.jpg',
              '/images/tekeningen/13.jpg',
              '/images/tekeningen/14.jpg',
              '/images/tekeningen/15.jpg',
              '/images/tekeningen/16.jpg',
              '/images/tekeningen/17.jpg',
              '/images/tekeningen/18.jpg',
              '/images/tekeningen/19.jpg']}
            />
          </Col>
          <Col xs={12} md={6} className="mediasection__col">
            <h2>3D Sfeerimpressie</h2>
            <PhotoCarousel slides={
              ['/images/3d/5.jpg',
                '/images/3d/6.jpg',
                '/images/3d/7.jpg',
                '/images/3d/8.jpg',
                '/images/3d/9.jpg',
                '/images/3d/10.jpg',
                '/images/3d/11.jpg',
                '/images/3d/12.jpg',
                '/images/3d/13.jpg',
                '/images/3d/14.jpg',
                '/images/3d/15.jpg',
                '/images/3d/16.jpg',
                '/images/3d/17.jpg',
                '/images/3d/18.jpg',
                '/images/3d/19.jpg',
                '/images/3d/20.jpg',
                '/images/3d/21.jpg']
}
            />
          </Col>
          <Col xs={12} md={6} className="mediasection__col">
            <h2>Shoppinglists</h2>
            <PhotoCarousel slides={[
              '/images/shopping/1.jpg',
              '/images/shopping/2.jpg',
              '/images/shopping/3.jpg',
              '/images/shopping/4.jpg',
            ]}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default MediaSection;
