import React from 'react';
import './Pricing.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const prices = [
  {
    title: 'Make-over',
    content: 'van 1 ruimte dmv een bezoek aan huis waarin advies wordt gegeven waar u zelf direct mee aan de slag kunt. 1 uur advies. per uur',
    price: '€ 50,00',
  },
  {
    title: 'Interieur',
    content: 'verandering voor 1 ruimte dmv een bezoek aan huis, waarna u een 3D impressie, een plattegrondtekening en een shoppinglist met kleuradvies ontvangt.',
    price: '€ 250,00',
  },
  {
    title: 'En',
    content: 'er is ook een pakket mogelijk waarbij u over meerdere ruimtes (of uw gehele huis) advies krijgt met de bijbehorende ontwerpen.',
    price: 'offerte',
  },
  {
    title: 'Restyling',
    content: 'op een andere manier of vragen hierover? Stel ze gerust en samen komen we vast tot een verrassend resultaat!',
    price: '€ 35,00 per uur',
  },
];


function Pricing() {
  return (
    <div className="pricing">
      <Container>
        <Row>
          {prices.map((price) => (
            <Col xs={12} md={3} className="pricing__box">
              <div className="pricing__box__content">
                <span className="pricing__box__content__title">{price.title}</span>
                <br />
                <small dangerouslySetInnerHTML={{ __html: price.content }} />
                <br />
                <span className="pricing__box__content__price">{price.price}</span>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}

export default Pricing;
